.upgradeModal{
    position: fixed;
    width: 100%; /* Full width (cover the whole page) */
    height: 100%; /* Full height (cover the whole page) */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.65); /* Black background with opacity */
    z-index: 1002;
}

.upgradeModal .actionCard{
    width: 700px;
    height: fit-content;
    border-radius: 31px;
    border: 1px solid #F9F9F9;
    background: #FFFFFF;
    overflow: hidden;
}

.actionCard h3{
    color: #0B0B0B;
    font-size: 17px;
    font-family: 'Plus Jakarta Sans';
    font-weight: 500;
}

.actionCard p{
    color: #757575;
    font-size: 15px;
    font-family:'Plus Jakarta Sans';
    font-weight: 400;
    line-height: 23px;
}

.actionCard .cardHeader{
    padding: 20px;
    /* border-bottom: 1px #F0F0F0 solid; */
}

/* .actionCard .cardContent{
    padding: 20px 30px;
} */

.actionCard button{
    width: 100%;
    padding: 10px 5px;
    font-size: 14px;
    background: #0B0B0B; 
    border-radius: 15px;
    border: 1px #0B0B0B solid;
    padding: 10px 60px;
    color: white;
    font-size: 16px;
    font-family: "Plus Jakarta Sans";
    font-weight: 500;
}

.tableDiv {
    height: max-content;
    width: 100%;
    overflow-x: auto;
    /* margin-top: 40px; */
}
.tableDiv table {
    height: max-content;
    width: 100%;
}

.tableDiv table tr th {
    /* padding: 1rem 0; */
    width: max-content;
    color: var(--primary-color);
    font-size: 16px;
    font-weight: 500;
    border-bottom: 1px solid #F0F0F0;
    padding: 10px 30px;
}

.tableDiv table tr td {
    /* padding: 1rem 0; */
    width: max-content;
    border-bottom: 1px solid #F0F0F0;
    padding: 10px 30px;
    box-sizing: border-box;
    color: #383838;
    font-size: 16px;
    font-weight: 500;
}

.dropDownWrapper{
    position: relative;
}

.groupDropDown{
    border-radius: 30px;
    background: rgba(0, 119, 181, 0.32);
    padding: 10px 20px;
    color:  var(--primary-color);
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    width: 150px;
    position: absolute;
}

.statistics{
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.statistics span{
    color: var(--primary-color);
}

.loadingWrapper{
    height: 400px;
    width: 100%
}

.loadingWrapper p{
    color: #383838;
    text-align: center;
    font-family: "Lato", sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.loader {
    width: 48px;
    height: 48px;
    background: var(--primary-color);
    display: inline-block;
    border-radius: 50%;
    box-sizing: border-box;
    animation: animloader 1s ease-in infinite;
  }
  
@keyframes animloader {
    0% {
      transform: scale(0);
      opacity: 1;
    }
    100% {
      transform: scale(1);
      opacity: 0;
    }
}