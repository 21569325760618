.acceptButton{
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    color: #FFFFFF;
    background: var(--primary-color);
    border-radius: 31px;
    width: 100%;
    padding: 10px 0;
    border: none;
}

.disabledButton{
    background: #D0D0D0;
}

@media (max-width: 480px) {
    .acceptButton{
        font-size: 14px;
        line-height: 16px;
    }
}