.wrapper{
    min-height: 100vh;
    background: url("https://s3.amazonaws.com/cdn.prime-bridge/prime-bridge-portal/prime-bridge-auth-bg.png");
    background-position: 0% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 40px 20px;
}

.card{
    width: 60vw;
    height: 60vw;
    background: #FFFFFF;
    border-radius: 31px;
    margin-top: 15vh;
    padding: 30px;
}

.card h3{
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 36px;
    text-align: center;
    color: var(--accent-color);
}

.card p{
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #000000;
}

@media (max-width: 550px) {
    .card{
        width: 80vw;
        height: 80vw;
    }
}