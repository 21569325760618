.wrapper{
    left: 45vw;
    top: 20vh;
    width: 400px;
    height: fit-content;
    position: absolute; 
    background: white; 
    border-radius: 30px; 
    border: 0.75px #E7E7E7 solid;
    z-index: 1003;
    padding: 20px;
}

.iconWrapper{
    width: 92px; 
    height: 92px; 
    background: var(--accent-color); 
    border-radius: 62px;
    margin-bottom: 20px;
}

.iconWrapper img{
    height: 60px;
}

.contentArea h4{
    text-align: center;
    color: var(--accent-color);
    font-size: 22px;
    font-weight: 500;
}

.contentArea h6{
    text-align: center;
    color: var(--primary-color);
    font-size: 16px;
    font-weight: 600;
}

.contentArea p{
    /* text-align: center; */
    color: #3E3E3E;
    font-size: 16px;
    font-weight: 400;
}

.contentArea button{
    color: white;
    font-size: 17px;
    font-weight: 500;
    background: var(--primary-color);
    border-radius: 30px;
    border: none;
    padding: 5px 20px;
    width: fit-content;
    margin-top: 10px;
}