.retryButton {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #ffffff;
    background: var(--accent-color);
    border-radius: 31px;
    /* width: 550px; */
    margin-top: 20px;
    border: none;
    padding: 10px 50px;
  }