.wrapper{
    width: 80%;
}

.dropDownField{
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #000000;
    border: 1.5px solid rgba(208,208,208, 0.5);
    border-radius: 31px;
    padding: 5px 10px;
    width: 100%;
    margin: 10px 0;
}

.dropDownField{
    position: absolute;
    padding: 5px 3px;
}

.dropDownOption{
    color: var(--primary-color);
    border-radius: 20.5px;
    padding: 5px 10px;
}

/* .dropDownOption:hover{
    background: rgba(217, 217, 217, 0.5);
} */

.bridgeDropDown, .sourceDropDown{
    background: #FFFFFF;
    border-radius: 20px;
}

.bridgeDropDown{
    z-index: 2;
}

.sourceDropDown{
    z-index: 1;
}

.selectedOption{
    padding: 0px 10px;
}

.optionsWrapper{
    position: relative;
    height: 40px;
    margin: 10px 0;
}

.wrapper textarea{
    border: 1.5px solid rgba(208,208,208, 0.5);
    border-radius: 31px;
    width: 100%;
    height: 345px;
    padding: 10px 20px;
    margin: 20px 0 40px 0;
    color: #000000;
    background: none;
}

.wrapper textarea::placeholder{
    color: #D0D0D0;
}

.wrapper button{
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 23px;
    color: #FFFFFF;
    background: var(--primary-color);
    border-radius: 31px;
    border: none;
    padding: 5px 40px;
}

.helpText{
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    color: #000000;
}

.successContainer{
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 23px;
    text-align: center;
    color: #000000;
    padding: 0 40px;
    position: absolute;
    top: 30px;
    left: 42%;
    width: 556px;
    height: 47px;
    background: #EFF8F4;
    border-radius: 50px;
    z-index: 101;
}
