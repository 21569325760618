.wrapper{
    width: 60%;
}

.topSection h3{
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 42px;
    color: #000000;
}

.tableDiv {
    height: max-content;
    width: 100%;
    overflow-x: auto;
}
.tableDiv table {
    height: max-content;
    width: 100%;
}

.tableDiv table tr th {
    padding: 1rem 0;
    width: max-content;
}

.tableDiv table tr td {
    padding: 1rem 0;
    width: max-content;
    border-top: 1px solid #ececec;
    box-sizing: border-box;
}

.deliveryTypeText{
    color: black;
    font-size: 16px;
    font-weight: 700;
}

.requestText{
    color: var(--primary-color);
    font-size: 16px;
    font-weight: 700;
}

.headingText h3{
    color: black;
    font-size: 28px;
    font-weight: 700;
}

.headingText p{
    color: black;
    font-size: 20px;
    font-weight: 400;
}