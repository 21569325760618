.wrapper h3{
    font-style: normal;
    font-weight: 700;
    font-size: 29px;
    line-height: 32px;
    align-items: center;
    color: #000000;
}
.formWrapper{
    width: 500px;
}


.formWrapper input{
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #000000;
    border: 1.5px solid rgba(208,208,208, 0.5);
    border-radius: 31px;
    padding: 5px 20px;
    width: 100%;
    margin: 10px 0;
    background: none;
    
}

.wrapper button{
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 23px;
    color: #FFFFFF;
    background: var(--primary-color);
    border-radius: 31px;
    padding: 8px 50px;
    border: none;
    margin-top: 20px;

}

.dropdownWrapper {
    position: relative;
    width: 100%;
  }
  
  .dropdownCard {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #000000;
    border: 1.5px solid rgba(208, 208, 208, 0.5);
    border-radius: 31px;
    padding: 5px 20px;
    width: 100%;
    margin: 10px 0;
    cursor: pointer;
    background: #fff;
  }
  
  .dropdownOptions {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background: #fff;
    border: 1.5px solid rgba(208, 208, 208, 0.5);
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 10;
  }
  
  .dropdownOption {
    padding: 10px 20px;
    cursor: pointer;
    transition: background 0.2s ease-in-out;
  }
  
  .dropdownOption:hover {
    background: rgba(208, 208, 208, 0.3);
  }
  
  .customInput {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #000000;
    border: 1.5px solid rgba(208, 208, 208, 0.5);
    border-radius: 31px;
    padding: 5px 20px;
    width: 100%;
    margin: 10px 0;
  }

  .wrapper .error{
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: red;
    margin: 10px 0;
  }

  .wrapper .success{
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: green;
    margin: 10px 0;
  }

  .radioLabel{
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #000000;
}

.checkboxContainer input[type=checkbox]{
    visibility:hidden;
    -webkit-appearance: none;
}

.checkboxContainer input[type='checkbox']:after {
    width: 25px;
    height: 25px;
    /* border-radius: 15px; */
    position: relative;
    background-color: transparent;
    border: 1.5px solid rgba(208,208,208, 0.5);
    border-radius: 5px;
    content: '';
    margin-top: 10px;
    display: inline-block;
    visibility: visible;
}

.checkboxContainer input[type='checkbox']:checked:after {
    width: 25px;
    height: 25px;
    /* border-radius: 15px; */
    position: relative;
    background: var(--primary-color);
    border: 1.5px solid var(--primary-color);
    border-radius: 5px;
    content: '';
    display: inline-block;
    visibility: visible;
}

.columnLabel {
    font-weight: 600;
    font-size: 16px;
    color: var(--primary-color);
    margin-bottom: 5px;
  }