.wrapper {
    position: absolute;
    
}

.cardWrapper{
    position: absolute; 
    right: 0;
    top: 20px;
    padding-bottom: 50px;
    z-index: 1002;
    /* overflow-y: auto; */
    height: fit-content;
}


.notificationCard{
    height: 80px;
    width: 350px;
    background: #fff;
    border: 0.75px #E7E7E7 solid;
    border-radius: 20px;
    transform: translateX(100%);
    -webkit-transform: translateX(100%);
    margin-bottom: 20px;
    overflow: hidden;
}

.iconWrapper{
    background: var(--accent-color); 
    border-radius: 62px;
    width: 52px;
    height: 52px;
    flex-shrink: 0;
    margin: 10px;
}

.desc{
    margin: 10px;
}

.desc h6{
    color: var(--accent-color);
    font-size: 17px;
    font-weight: 500; 
    margin: 0;
}

.desc p{
    color: #3E3E3E;
    font-size: 15px;
    font-weight: 400;
    margin: 0;
}

.leftSide{
    height: 100%;
    width: 70px;
    border-left: 0.75px #E7E7E7 solid;
}

.topInner{
    height: 50%;
    width: 100%;
}

.bottomInner{
    height: 50%;
    width: 100%;
    border-top: 0.75px #E7E7E7 solid;
}

.topInner, .bottomInner{
    color: #7B7B7B;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    padding: 6px 0;
}

.slideUp {
    animation: slide-up 0.5s forwards;
    -webkit-animation: slide-up 0.5s forwards;
    margin-right: 30px;
}

.slideIn {
    animation: slide-in 0.5s forwards;
    -webkit-animation: slide-in 0.5s forwards;
    margin-right: 30px;
}

.slideOut {
    animation: slide-out 0.5s forwards;
    -webkit-animation: slide-out 0.5s forwards;
    
}

@keyframes slide-up {
    0%,
    50% {
      transform: translateY(100%);
      opacity: 0;
    }
     
    60%,
    100% {
      transform: translateY(0);
      opacity: 1;
    
    }
  }

  @-webkit-keyframes slide-up {
    0%,
    50% {
      transform: translateY(100%);
      opacity: 0;
    }
     
    60%,
    100% {
      transform: translateY(0);
      opacity: 1;
    
    }
  }
    
@keyframes slide-in {
    100% { transform: translateX(0%); }
}

@-webkit-keyframes slide-in {
    100% { -webkit-transform: translateX(0%); }
}
    
@keyframes slide-out {
    0% { transform: translateX(0%); }
    100% { transform: translateX(100%); }
}

@-webkit-keyframes slide-out {
    0% { -webkit-transform: translateX(0%); }
    100% { -webkit-transform: translateX(100%); }
}