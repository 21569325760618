.wrapper{
    width: 60%;
}

.topSection h3{
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 42px;
    color: #000000;
}

.topSection button{
    width: 181px;
    height: 40px;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    color: var(--primary-color);
    border: 1.5px solid var(--primary-color);
    background: none;
    border-radius: 31px;
    gap: 10px;
}

.tableDiv {
    height: max-content;
    width: 100%;
    overflow-x: auto;
}
.tableDiv table {
    height: max-content;
    width: 100%;
}

.tableDiv table tr th {
    padding: 1rem 0;
    width: max-content;
}

.tableDiv table tr td {
    padding: 1rem 0;
    width: max-content;
    border-top: 1px solid #ececec;
    box-sizing: border-box;
}

.twoFAText h3{
    font-size: 28px;
    font-weight: 700;
    line-height: 42px;
    letter-spacing: 0em;
}

.twoFAText h6{
    margin-top: 20px;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0px;
}