.hidden {
  display: none !important;
}

.tradesMainContainer {
  height: max-content;
  /* background-color: #eef8f9; */
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3rem 3.5rem 0 3rem;
}

/* CARD STYLES */
/* .cardContainer {
  border-radius: 30px;
  background-color: white;
  width: 100%;
  height: max-content;
  border: 2px solid #41B5BD;
  border-radius: 30px;
  margin-bottom: 2rem;
} */

.cardContainer div:first-child {
  display: flex;
  justify-content: space-between;
  padding: 2rem 0;
}

.cardContainer div button {
  background-color: var(--primary-color) !important;
  border-radius: 30px !important;
  color: white !important;
  display: flex !important;
  align-items: center !important;
  gap: 0.3rem !important;
  height: 42px !important;
}

.cardContainer div h2 {
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 33px;
  text-align: left;
  letter-spacing: -0.3px;
  color: #252525;
}

/* OPEN TRADES STYLES */
.tableDiv {
  height: max-content;
  width: 100%;
  overflow-x: auto;
}
.tableDiv table {
  height: max-content;
  width: 100%;
}

.tableDiv table tr th {
  padding: 1rem 0;
  width: max-content;
}

.tableDiv table tr td {
  padding: 1rem 0;
  width: max-content;
  border-top: 1px solid #ececec;
  box-sizing: border-box;
}

.tableDiv table tr td p {
  margin-bottom: 0.1rem;
}

/* TOP SECTION STYLES */

.btnDiv {
  display: flex;
  gap: 0.3rem;
  align-items: center;
  background-color: transparent;
}

.topSectionContainer {
  /* background-color: rgba(89, 186, 193, 0.4); */
  padding: 0 3.5rem 0 3rem;
  display: flex;
  justify-content: space-between;
  /* height: 100px; */
}

.left {
  display: flex;
  gap: 1.5rem;
}

.left div {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.left div p {
  cursor: pointer;
  color: #666666;
  font-weight: 600;
  margin-bottom: 0.8rem;
  margin-top: 0.8rem;
}

.left div span {
  height: 4px;
  width: 100%;
  transition: background-color 1s !important;
}

.left div div:before {
  transition: width 0.2s ease, background-color 0.2s ease;
}
.left div div:after {
  transition: width 0.2s ease, background-color 0.2s ease;
}

/* .right {
  display: flex;
  gap: 0.8rem;
  padding-bottom: 1rem;
} */

.right button {
  height: 40px !important;
}

.numberContainer{
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 123.6%;
  color: var(--primary-color);
  width: 35px;
  height: 35px;
  border: 0.840647px solid var(--primary-color);
  border-radius: 3.36259px;
  margin: 0 5px;
}

.pagination{
  
}

/* MEDIA QUERIES SMALL SCREENS */

@media (max-width: 767px) {
  .topSectionContainer {
    padding: 0 1.5rem 0 1rem;
  }

  .tradesMainContainer {
    padding: 3rem 1.5rem 0 1rem;
  }

  .left div p {
    margin-bottom: 0.5rem;
  }

  .left {
    display: flex;
    gap: 1.2rem;
  }

  .right svg{
    height: 15px !important;
  }
}
