.wrapper a{
 text-decoration: none;
}

.prevLink{
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
    color: var(--primary-color);
}

.activeLink{
    font-weight: 500;
    color: #000000;
}

.searchContainer{
    background: #FAFAFA;
    border-radius: 31px;
    height: 42px;
    margin: 40px 0;
    width: 350px;
    
}

.searchContainer input{
    background: none;
    border-radius: 31px;
    border: none;
    height: 42px;
    color: #8F8F8F !important;
}

.searchContainer input::placeholder{ 
    color: #8F8F8F;
}

.searchContainer input:focus {
    outline: none;
    outline-width: 0;
    background: none;
}

.searchContainer button{
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 23px;
    text-align: center;
    color: #FFFFFF;
    background: var(--primary-color);
    border-radius: 31px;
    width: 120px;
    height: 42px;
    border: none;
}

.wrapper h3{
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 42px;
    color: #000000;
}

