

.topCardWrapper{
    height: 93px;
}



.topCardWrapper h3{
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    line-height: 34px;
    align-items: center;
}

.topCardWrapper h4{
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 24px;
}

.topCardWrapper p{
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: var(--primary-color);
}

.checkCircle{
    border-radius: 100%;
    border: 2px solid var(--accent-color);
    height: 15px;
    width: 15px;
    margin-left: 5px;
}

.horizontalDivider{
    height: 1px;
    width: 100%;
    background: #D0D0D0;
}

.stepWrapper{
    height: fit-content;
}

.stepWrapper h6{
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #000000;
}

.stepWrapper h6 span{
    color: var(--accent-color);
}

.stepWrapper p{
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #141414;
}

.stepNav{
    width: fit-content;
    /* margin-top: 3px; */
    margin-right: 10px;
}

.stepDot{
    width: 14px;
    height: 14px;
    background: linear-gradient(0deg, var(--accent-color), var(--accent-color)), linear-gradient(0deg, var(--primary-color), var(--primary-color)), var(--primary-color);
    border-radius: 100%;
}

.verticleDivider{
    width: 2px;
    min-height: 105px;
    background: #C4C4C4;
}

.card{
    border: 1.5px solid rgba(208,208,208, 0.5);
    border-radius: 31px;
    width: 100%;
    padding: 10px 20px;
    margin: 20px 0;
    color: #000000;
    background: none;
}

.card textarea{
    background: #F3F3F3;
    border-radius: 25px;
    width: 100%;
    height: 145px;
    padding: 10px 20px;
    margin: 0px 0 20px 0;
    color: #000000;
    border: none;
}

.card textarea::placeholder{
    color: #D0D0D0;
}

.card button{
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 23px;
    color: #FFFFFF;
    background: var(--primary-color);
    border-radius: 31px;
    border: none;
    padding: 5px 40px;
}