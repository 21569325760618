:root {
  --primary-color: #0077B5;
}

#switcher {
  width: 48.59px;
  height: 22px;
  border-radius: 50px;
  position: relative;
  background-color: #c4c4c4 !important;

  .handle {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 4px;
    transition: 0.5s;
    background-color: white !important;
    width: 16px;
    height: 16px;
    border-radius: 50%;
  }
}

#switcher.active {
  background-color: var(--primary-color) !important;
  .handle {
    left: calc(100% - 20px);
  }
}
