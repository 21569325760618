.wrapper {
  /* min-height: 100vh; */
  /* background: url("https://s3.amazonaws.com/cdn.prime-bridge/prime-bridge-portal/prime-bridge-auth-bg.png");
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover; */
  padding: 40px 0;
}

.videoTag {
  z-index: -1;
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  object-fit: cover;
}

.wrapper h2 {
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 34px;
  letter-spacing: -0.3px;
  text-transform: capitalize;
  color: #000000;
}

.employeeLoginButton {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #ffffff;
  background: var(--accent-color);
  border-radius: 31px;
  width: 550px;
  margin-top: 20px;
  border: none;
  padding: 10px 0;
}
